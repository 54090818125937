<template>

    <div class="tenant-container">
        <div class="form-section">
            <contact v-if="contact.id" :contact="contact"></contact>
        </div>

        <div class="form-section">
            <access v-if="contact.id" :contact="contact"></access>
        </div>

        <div class="form-section">
            <!-- <contacts-overview :contact="$store.getters.getUserData" ></contacts-overview> -->

            <contacts-overview v-if="contact.id" :contact="contact" ></contacts-overview>
        </div>
    </div>

</template>

<script type="text/babel">

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { mapGetters } from 'vuex';
    import ContactsOverview from '../contacts/ContactsOverview.vue'
    import Contact from '../contacts/Contact.vue'
    import Access from '../contacts/Access.vue'

    export default {
        name: "TenantView",
        data() {
            return {
                tenant:{
                    User:{}
                },
                contact: {
                    id: null
                },
                leases: []
            }
        },
        components:{
            ContactsOverview,
            Contact,
            Access
        },
        created(){
            //this.fetchLeases();
            this.fetchTenant();
        },
        computed:{
            ...mapGetters({
                getUserData: 'authenticationStore/getUserData'
            })
        },
        filters:{
          formatDate(date){
              return moment(date).format('MM/DD/YYYY');
          }
        },
        methods:{
//            fetchLeases(){
//                api.get(this, api.LEASES).then(r => {
//                    this.leases = r.leases;
//                });
//
//            },
            fetchTenant(){
                api.get(this, api.CONTACTS + this.getUserData.id).then(r => {
                    this.contact = r.contact;
                });


            }
        }
    }


</script>

<style scoped>
    .tenant-container{
        max-width: 1200px;
        width: 100%;
        margin: 50px auto;
        padding: 15px;
        border: 1px solid #e2e2e2;
        background-color: #fff;
        box-shadow: 1px 1px 4px 0px #dee5e7;
    }

    @media (max-width: 767px) {
        .tenant-container{

            box-sizing: border-box;
            max-width: 100%;
            margin-top: 10px;
            margin-right: 10px;
            margin-left: 10px;
            padding: 0px;
            width: auto;
        }
    }
</style>